import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import CustomModal from "../common/shared/CustomModal";
import { toast } from 'react-toastify';
import { userSiteAccess, } from "../../../redux/slices/auth";
import { useAuthSelector } from "../../../redux/selector/auth";
import Cookies from "js-cookie";

const 
HomeScreenPassword = (props) => {

    const customModalStyles = {
        backdrop: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Example: semi-transparent black
          /* Add any other desired styles */
        }
    };


    const dispatch = useDispatch();
    const authSelector = useAuthSelector();
    const [formData, setFormData] = useState({
        password: "",
    });
    const [key, setKey] = useState(Math.random());
    const [errorMessages, setErrorMessages] = useState({});
    const [modalDetail, setModalDetail] = useState({
        show: true,
        title: "",
        flag: "homescreen",
    });
    const [authToken, setAuthToken] = useState(Cookies.get("userSiteAccess"));

    //onchange input 
    const handleChange = (e) => {
        const { value, name } = e.target;
        setFormData({ ...formData, [name]: value.trim() });
        setErrorMessages({ ...errorMessages, [name]: "" });

    }

    //close Modal
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };


    //form login
    const handleUserSubmit = (e) => {
        e.preventDefault();
        if (!formData?.password) {
            toast.error("Please enter password");
            return;
        }
        // else if (formData?.password.length <= 7) {
        //     toast.error("Password should be maximum 8 character")
        //     return;
        // }
        let params = {
            password: formData?.password,
        } 
        dispatch(userSiteAccess({
            ...params, cb(res) {
                if (res?.data?.payload?.status) {
                    handleOnCloseModal();
                    var now = new Date();
                    now.setTime(now.getTime() + 1 * 3600 * 1000);
                    setAuthToken(document.cookie = "userSiteAccess=yes; expires=" + now.toUTCString() + "; path=/")
                }
            }
        }))
    }

    return (
        <>
            {authToken ? ""
                : <CustomModal
                    ids={modalDetail.flag == "homescreen" ? 'homescreen' : ''}
                    key={key}
                    show={modalDetail.show}
                    title={modalDetail.title}
                    style={customModalStyles.backdrop}
                    child={
                        modalDetail.flag == "homescreen" ? (
                            <div className='login'>
                                <div className="row justify-content-center">
                                    <div className="col-md-12 ">
                                        <div className="detailsContainer_">
                                            <form onSubmit={(e) => handleUserSubmit(e)} className='signupForm row justify-content-center mt-3'>
                                                <div className='form-group col-md-12 mb-3'>
                                                    {/* <h3>This is not our official website Visit <a href="https://oppswell.com/">oppswell.com</a></h3> */}
                                                    <label>Enter your password</label>

                                                    <input autoComplete="on" value={formData?.password} name="password" type='password' className='customFormControl' placeholder='Password' onChange={(e) => handleChange(e)} />
                                                </div>
                                                <div className='col-12 col-md-12 col-lg-12  mt-2 mb-3 '>
                                                    <button className='primaryBtn mt-2' type="submit">
                                                        {authSelector.loading && (
                                                            <span className="spinner-border spinner-border-sm"></span>
                                                        )}
                                                        &nbsp;&nbsp;
                                                        <span>Submit</span>
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                            : ""
                    }
                    size="sm"
                    onCloseModal={() => handleOnCloseModal()}
                />
            }
        </>
    )
}

export default HomeScreenPassword;